.add-person-modal {
  position: fixed;  /* Fixed position */
  z-index: 1;  /* Sit on top */
  left: 50%;  /* Centered horizontally */
  top: 50%;  /* Centered vertically */
  transform: translate(-50%, -50%);  /* Account for actual dimensions */
  width: 80%; /* Full width */
  min-width: 350px;
  max-width: 400px;
  background-color: #ffffff;  /* White background */
  padding: 10px;  /* Some padding */
  border: 1px solid #ccc;  /* A border */
  border-radius: 4px;  /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for better appearance */
}

.person-btn {
  margin: 3px 3px 0 0;
  padding: 8px 12px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  background-color: #007bff;

  /* Hover effect */
  &:hover {
    background-color: #0056b3;
  }

  /* Focus effect */
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }

  /* Active effect */
  &:active {
    background-color: #0056b3;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }
}


.add-person-modal input {
  margin-left: 0px;
  margin-bottom: 3px;
  margin-right: 5px;
  width: 150px;
}

.add-person-modal .phone-input {
  margin-left: 0px;
}