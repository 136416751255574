::ng-deep .pac-container {   
  z-index: 9999;
}
.form-row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.form-avatar {
  display: flex;
}

.form-avatar span h5 {
  margin-left: 1rem;
  margin-top: 0.5rem;
}

.form-row input[type='text'],
.form-row textarea,
.form-row .react-datepicker-wrapper {
  flex: 1;
  padding: 12px;
  border-radius: 8px;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s;
}

.form-row button {
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.form-row button:hover {}

.form-row label {
  flex: 0 0 120px;
  font-weight: bold;
  margin-right: 10px;
}

.separation-line {
  width: 100%;
  margin: 1rem 0;
  border: solid 1px #e6e6e6;
}

.event-title {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%
}

.form-container h3 {
  text-align: center;
  /* Center the h2 text */
}

.rows-container {
  margin-bottom: 10px;
}

/* ... (existing CSS) */

.form-container button {
  margin-top: 10px;
}

.button-container {
  display: flex;
  justify-content: start;
}

.add-cohost-text {
  margin-top: 0.5rem;
}

.button-container button {
  margin-right: 0.5rem;
}

.datepicker {
  margin-left: 1rem;
}

.end-time-container:hover #show-end-time-text {
  text-decoration: underline;
}

.event-location {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  width: 100%
}

.privacy-toggle {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  width: 100%
}