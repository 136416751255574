.event-cards-container {
  display: flex;
  flex-wrap: nowrap;
  /* Ensure cards don't wrap to the next line */
  justify-content: flex-start;
  /* Align cards to the start */
  overflow-x: auto;
  /* Enable horizontal scrolling when the content exceeds the container width */
  width: 100%;
  /* Take the full width of its parent */   
}

.event-cards-wrapper {
  width: 100%;
  background: transparent;
}

.event-cards-title {
  text-align:left;
  margin: 1rem;
  /* Centers the title */
  margin-bottom: 20px;
  /* Adds some space below the title */
  /* Additional styles you'd like for the title */
}