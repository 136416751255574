.create-form-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ;
  width: 45%;
  height: 100%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 3rem;
  padding: 0.5rem;
}

.create-event-container {
  margin: 1rem;
  margin-bottom: 80px;
}

.create-form {}

.create-form-row {}

.datepicker-custom {
  margin: 1rem;
}

/* Custom styles for the Autocomplete */
.autocomplete-custom {
  width: 100%;
}

.row {
  margin: 1rem;
}

.Toastify__toast-container {
  z-index: 900000;
  filter: none !important;
}