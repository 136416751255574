.share-link-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  max-height: 300px;
  margin: auto;
  border: 1px solid #ddd;
  border-radius: 10px;
  flex-direction: column;
}

.share-link-modal-overlay {
  background-color: rgba(0, 0, 0, 0.75); /* Black with 75% opacity */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
}

.share-link-modal-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
}

.share-link-modal-social-buttons {
  display: flex;
  flex-direction: row;
  margin-top: 1.5rem;
}

.share-social-button {
  margin: 1rem;
  display: flex;
  flex-direction: column;
}

.share-social-button-text {
  text-align: center;
  margin-top: 0.5rem;
}

.share-link-modal-header {
  display: flex;
  justify-content: space-between;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  padding: 15px;
}

.copy-link-container {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 0.5rem;
}

.copy-link-url {
  flex-grow: 1;
}

.copy-link-button {
  cursor: pointer;
}

.share-link-modal-divider {
  margin-top: 2rem;
  margin-bottom:1rem;
  border: solid 1px #e6e6e6;
}
