.contact-page-container {
}

.contact-page-header {
  margin-bottom: 20px;
  font-weight: bold;
}

.friend-row {
  display: flex;
  align-items: center;
  border: 1px solid #eaeaea;
  padding: 0.5rem;
}

.friend-row:hover {
  background-color: #eaeaea;
}

.friend-row span.tags {
  margin-left: 10px;
  background-color: #eaeaea;
  border-radius: 5px;
  padding: 3px 5px;
}

.friend-tag {
  border: 1px solid #eaeaea;
  margin-left: 2px;
  margin-right: 5px;
  font-size: small;
  padding: 2px;
  background-color: antiquewhite;
}

.friend-info {
  margin-left: 1rem;
}

.friend-tags {
  display: flex;
  flex-direction: row;
}
