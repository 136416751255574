/* Colors */
:root {
  --yinmn-blue: rgba(73, 81, 148, 0.05);
  /* Making it almost invisible */
  --orange: rgba(255, 165, 0, 0.05);
  /* Making it almost invisible */
  --light-gray: rgba(237, 237, 237, 0.05);
  /* Barely noticeable */
  --green: rgba(171, 255, 214, 0.05)
}

.invitationCard {
  position: relative;
  width: 300px;
  height: 200px;
  border-radius: 20px;
  box-sizing: border-box;
  font-family: 'Product Sans', sans-serif;
  color: #2C3E50;
  margin: 1rem;
  border: 2px solid transparent;
  transition: border-color 0.3s ease;

  /* A basic padding to ensure there's space between the content and the card edges */
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 5px;
  /* Space between elements */
}

/* A smaller font-size for the title in the card */
.invitationCard h2 {
  font-size: 16px;
  /* Increased font-size for better visibility */
  margin-bottom: 10px;
  /* Added some margin for spacing */
  font-family: 'Arial', sans-serif;
  text-align: center;
}

.invitationCard p {
  font-size: 14px;
  /* Font size for the description and other details */
  margin: 0;
  /* Remove default margins */
  text-align: left;
  /* Align left for details */
}

.invitationCard .div-line {
  position: sticky;
  width: '100%';
  border-top-style: solid;
  margin-top: 10px;
  margin-bottom: 10px;
}

.invitationCard:hover {
  border-color: rgba(199, 111, 104, 0.5);
  /* A general hover color. It gets overridden by the specific card styles below. */
}

.color-accepted {
  background-color: rgb(205, 231, 241);
  border: 1px solid green
}

.color-accepted:hover {
  border: 2px solid green
  /* A faint version of the orange for the "enjoy" color */
}

.color-declined {
  background-color: rgb(205, 231, 241);
  border: orange solid 1px
}

.color-declined:hover {
  border: orange solid 2px
  /* A faint version of the orange for the "enjoy" color */
}

.color-expired {
  background-color: rgb(192, 192, 192);
}

.color-expired:hover {
  background-color: rgb(192, 192, 192);
  border-color:rgb(192, 192, 192);
}

.color-sport {
  background-color: var(--light-gray);
}

.color-sport:hover {
  border-color: rgba(200, 200, 200, 0.3);
  /* A fainter version of the light gray for the "sport" color */
}

.color-cool {
  background-color: var(--yinmn-blue);
}

.color-cool:hover {
  border-color: rgba(73, 81, 148, 0.2);
  /* A very faint version of the yinmn-blue for the "cool" color */
}

.color-default {
  background-color: rgb(205, 231, 241);
}

.color-default:hover {
  border-color: grey;
  /* A very faint version of the yinmn-blue for the "cool" color */
}