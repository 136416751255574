.expired-event-cards-wrapper {
  display: flex;
  flex-direction: column;
}

.expired-event-cards-container {

}

.expired-event-card {
  display: flex;
  justify-content: center;
}