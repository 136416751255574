.home-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: rgb(255, 249, 237);
}

.home-container .card-container {
  width: 100%;
  max-width: 767px;
  min-height: 100vh;
  background-color: rgba(255, 255, 255, 0.15);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
  background: linear-gradient(0deg, #ffbe62, #b0d6ff);
}

/* Adjust the font size of the event card titles on smaller screens */
@media (max-width: 767px) {
  .event-card-title {
    font-size: 1.2rem;
  }

  .form-card {
    padding: 0;
    margin: 0;
  }
}

.form-container {
  width: 100%;
}

.card-container-body {
  padding-bottom: 70px;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-y: scroll; /* Always show scrollbar */
}