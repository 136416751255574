/* styles.css */
.loading-page {
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 8px solid blue;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.center-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}