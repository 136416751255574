.inline-edit-input {
  background-color: transparent;
  border: 0;
  padding: 8px;
}

.inline-edit-input:hover {
  background-color: #d3d3d3;
  cursor: pointer;
}

.inline-edit-multiinput {
  resize: none;
  min-height: 14px;
  max-height: 500px;
  width: 100%;  /* Set width */
  min-height: 100px;  /* Set minimum height */
  padding-left: 5px;
  overflow: auto;
}

.inline-edit-multiinput:focus {
  outline: 5px auto Highlight;
  outline: 5px auto -webkit-focus-ring-color;
}

.inline-edit-multiinput:active textarea {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  /* Remove WebKit user-resize widget */
  resize: none;
}
