
.location-search-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.location-modal-header,
.location-modal-footer {
  display: flex;
  justify-content: space-between;
  padding: 15px;
}

.location-modal-header h3 {
  margin: 0;
}

.close-button,
.save-button {
  cursor: pointer;
}

.location-modal-content {
  text-align: center;
}

.autocomplete-section,
.map-section {
  padding: 20px;
}

.location-modal-container {
  background-color: white;
  padding: 1rem;
  max-width: 500px;
  max-height: 800px;
  width: 80%
}