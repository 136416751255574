.row-component {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .row-component .name-input {
    flex: 1;
    margin-right: 10px;
  }
  
  .row-component .phone-input {
    flex: 1;
    margin-right: 10px;
  }
  
  .row-component button {
    margin-left: 10px;
  }
  