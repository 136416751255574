.navbar {
    display: flex;
    list-style: none;
    justify-content: space-between;
    align-items: center;
    gap: 1px;
    max-height: 130px;
    width: 100%; /* Add this line */
    -webkit-box-shadow: 0 4px 6px -6px #222;
    -moz-box-shadow: 0 4px 6px -6px #222;
    background-color: #b8d3ef;
    border-radius: 10px;
    box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.0);
}

.navbar-menu {
}

.item-menu-icon {
    color: black;
    stroke-width: 1;
}

.menu {
    position: absolute;
    list-style-type: none;
    background-color: white;
    border: 1px solid #e7e7e7;
    align-items: left;
}

.menu-item {
    margin:0.1rem;
    justify-content: left;
    padding-left: 0;
}

.item-btn {
    background-color: white;
    color: black;
    border: 1px solid #e7e7e7;
    margin-right: 2px;
}

.navbar-left {
    margin-left: 1rem;
    font-size: 30px;
    font-weight: bold;
}

.navbar-left a {
    text-decoration: none;
    color: inherit;  /* Takes the color from the parent */
}

.navbar-right {
    margin-right: 0.5rem;
}

.avatar {
    border: 1px solid #e7e7e7;
}