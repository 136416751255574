.ai-prompt-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 700px;
  margin: 1rem;
}

.ai-prompt-container {
  width: 90%;
}

.ai-prompt-text-box{
  width: 100%;
}

.ai-prompt-text-box-input{
  width: 100%;
}

.ai-prompt-btn {
  margin-top: 1rem;
  background-color: rgb(235, 209, 185);
  width: 30%;
}