.attendee-detail-modal {
  position: fixed;  /* Fixed position */
  z-index: 1;  /* Sit on top */
  left: 50%;  /* Centered horizontally */
  top: 50%;  /* Centered vertically */
  transform: translate(-50%, -50%);  /* Account for actual dimensions */
  width: 80%; /* Full width */
  min-width: 350px;
  max-width: 400px;
  background-color: #ffffff;  /* White background */
  padding: 10px;  /* Some padding */
  border: 1px solid #ccc;  /* A border */
  border-radius: 4px;  /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for better appearance */
}