* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Product San', sans-serif;
}

.otp-login-container {
  padding-top: 250px;
  height: 100vh;
  width: 100vw;
}

.form-card .card {
  backdrop-filter: blur(50px);
  /* Apply blur */
  background-color: rgba(255, 255, 255, 0.3);
  /* Slight transparency */
  border: none;
}

.login-create-event {

}

#recaptcha-signin {
  /* Add any custom styles for the recaptcha container here */
}

.login-card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.create-event-btn {
  margin-top: 0 !important;
  border-color: grey !important;
}

.text-center {
  text-align: center;
}

.mb-4 {
  margin-bottom: 1rem;
}

.otp-container {
  /* Add any custom styles for the OTP input container here */
}

.phone-input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.phone-input {
  width: 100%;
}

.w-100 {
  width: 100%;
}

.mt-3 {
  margin-top: 0.75rem;
}

.custom-button {
  background: rgba(0,0,0,0.1);
  border: none;
  color: white;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  margin-top: 1rem;
  border-radius: 4px;
  transition-duration: 0.4s;
}

.custom-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
  /* Slightly opaque white on hover */
  color: white;
  /* or any preferred hover color for the icon */
  text-decoration: underline;
}

@media (max-width: 767px) {
  .form-card {
    padding: 0;
  }
}