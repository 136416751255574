/* App.css */
.avatar-menu {
  display: flex;
  align-items: center;
  border-radius: 5px; /* Making it rounded */
  border: none;
  cursor: pointer;
  background-color: transparent;
  box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.1); /* Adding a bottom-only shadow */
  transition: background-color 0.2s ease;

  /* Removing default button styles */
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  justify-content: center;  /* Center the contents */
}

.avatar-menu:hover {
  background-color: rgba(255, 255, 255, 0.3); /* 0.7 is the opacity level */
}

.avatar-menu-bars {
  margin-right: 8px;
  stroke: 1px;
  padding-left: 1rem;
}

.avatar-menu-avatar {
  margin-right: 10px;
  stroke-width: 1;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
}

.menu-icon, .avatar-icon {
  font-size: 24px;
}

.avatar-icon {
}

/* Append this to your AvatarMenu's CSS */
.avatar-menu-wrapper {
  position: relative;
}

.avatar-dropdown-menu {
  position: absolute;
  width: 200px;
  top: 100%;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  left: -25px;  /* Align the dropdown with the left edge of the AvatarMenu */
  margin-top: 5px;
}

.avatar-user-info {
  padding: 0.5rem;
}

.menu-item {
  padding: 10px;
  display: block;
  border-bottom: 1px solid #eee; /* Separator line */
  cursor: pointer;
}

.menu-item:last-child {
  border-bottom: none;
}

.menu-item:hover {
  background-color: #f7f7f7;
}
