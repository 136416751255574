
.footer-container {
  display: flex;
  justify-content: space-around;
  padding: 1em;
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 767px;
  background-color: #f9c172;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.0);

}

.footer-button {
  background: none;
  border: 0px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2em;
  padding: 0.2em 0.5em;
  transition: background-color 0.3s;
}

.footer-button:focus {
  outline: none;
}

.footer-button:hover {
  background-color: #f2f2f2;
}

.footer-container a {
  color: black;
  text-decoration: none;
}