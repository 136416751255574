
.event-screen-container {
  background-color: white;
  border-radius: 3px 3px 0 0; /* Curved top edges */
  padding: 20px; /* Adjust this value as per your requirement */
  margin: 0 auto; /* Horizontally center the container */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for some depth */
  overflow: hidden; /* This ensures everything inside respects the border-radius */
  width: 100%;
}

.event-cover-photo {
  width: 100%;
  height: 200px;  /* or whatever height you prefer */
  overflow: hidden;
  margin-bottom: 1rem;
}

.event-cover-photo img {
  width: 100%;
  object-fit: contain;
}

.event-details {
  display: flex;
  justify-content: space-between;
}

.event-title {
  display: flex;
  justify-content: space-between;
  border-bottom: #555;
}

.event-title-avatar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.event-title-avatar-add {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.event-title-avatar-add:hover{
  background-color: #ddd;
  border-radius: 50px;
}

.event-description {
  margin-top: 1rem;
  margin-bottom: 1rem;

}

.event-date-time {
  display: grid;
  place-content: center;
  padding-right: 2rem;  /* Added padding for better appearance */
  padding-left: 1rem;
  margin: 3px;
  border-right: 1px solid #ddd;  /* Vertical separation line */
}

.event-location-time--editable:hover {
  background-color: #ccc;
  cursor: crosshair;
}

.event-meta-instructions h5 {
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}

.separator {
  width: 1px;
  height: 100%;
  background-color: #ddd;
  margin: 0 10px;
}

.venue-details {
  flex: 2;
  text-align: right;
  padding-left: 10px;  /* Added padding for better appearance */
}

.venue-details .address {
  font-size: 0.9rem;
  color: #555;
}

.venue-details .venue-name {
  font-size: 1.2rem;
}

.links a {
  display: block;
  color: #007BFF;
  text-decoration: none;
  margin-top: 5px;
}

.horizontal-separator {
  width: 100%;
  height: 1px;
  background-color: #ddd;
  margin: 1rem 0;
}

.attendees {
  display: flex;
  flex-direction: column;
  gap: -20px;
  align-items: start;
}

.add-invites-icon :hover{
  background: #ddd;
  margin: 2px;
}

.add-invites-icon {
  background:transparent;
  border: none;
  margin-bottom: 0.5rem;
}

.event-avatar-icon {
  display: flex;
  justify-self: center;
  align-items: center;
  border-style: solid;
  border-width: 2px;
}

.border-green {
  border-color: green;
}

.border-red {
  border-color: red;
}

.border-grey {
  border-color: grey;
}


.chat-window {
  border: 1px solid #ddd;
  padding: 10px;
  max-height: 250px;
  overflow-y: auto;
  border-radius: 5px;
  margin-top: 1rem;
}

.message {
  padding: 8px 12px;
  border-radius: 15px;
  background-color: #f1f1f1;
  margin-bottom: 10px;
  position: relative;
  max-width: 80%;
}

.message:before {
  content: '';
  position: absolute;
  top: 10px;
  left: -10px;
  border-width: 6px 10px 6px 0;
  border-color: transparent #f1f1f1 transparent transparent;
}

.message strong {
  display: block;
  color: #555;
  font-size: 0.8rem;
  margin-bottom: 5px;
}

.people-list-container {
  display: flex;
  align-items: center;
}

.add-people-icon {
  cursor: pointer;
  margin-right: 8px; /* Adjust this value to give some spacing between the avatars and the icon */
}

.add-people-icon:hover {
  background-color: beige;
}

/* Add this to your EventDetail.css */
.white-space-pre-line {
  white-space: pre-line;
}

.modal-close-btn {
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
  background: none;
  border: none;
  font-size: 18px;
}

.modal-close-btn:hover {
  border-radius: 50%;
  background-color: lightgrey;
}

.editable-event-component {
  display: flex;
}

.additional-details h5 {
  margin-bottom: 1rem;
}

.add-additional-details {
  margin-top: 0.5rem;
  margin-bottom: 2rem;
}

.add-additional-details:hover {
  background-color: #ddd;
  cursor: pointer;
  border-radius: 50px;
}

.attendee-detail-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100000;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.people-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.date-picker-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.save-profile-btn {
  margin: 5px;
}
