.event-page-view-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.event-page-detail {
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 3px;
  overflow: scroll;
}

.action-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  background-color: transparent;  /* Make background transparent */
}

.action-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  cursor: pointer;
  text-align: center;
  background-color: transparent;
  border-width: 0px;
}

.action-btn a {
  color: black;
  text-decoration: none;
}

.action-btn.accept {
}

.action-btn.accept:hover {
  background-color: rgba(255, 255, 255, 0.3); /* 0.7 is the opacity level */
}

.action-btn.reject {
}
.action-btn.reject:hover {
  background-color: rgba(255, 255, 255, 0.3); /* 0.7 is the opacity level */
}

.action-btn.delete {
}
.action-btn.delete:hover {
  background-color: rgba(255, 255, 255, 0.3); /* 0.7 is the opacity level */
}

.action-btn.publish {
}

.action-btn.publish:hover {
  background-color: rgba(255, 255, 255, 0.3); /* 0.7 is the opacity level */
}

.action-btn.cancel {

}
.action-btn.cancel:hover {
  background-color: #e14135;
}

.action-btn.save {

}
.action-btn.save:hover {
  background-color: rgba(255, 255, 255, 0.3); /* 0.7 is the opacity level */
}

.action-btn.share {
}

.action-btn.share:hover {
  background-color: rgba(255, 255, 255, 0.3); /* 0.7 is the opacity level */
}

.event-page-rsvp-state {
  width: 100%;
  box-sizing: border-box; /* Include padding and border in element's total width and height */
  overflow: hidden; /* Hide overflowing content */
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.rsvp-container {
  border-radius: 3px;
  padding: 10px;
  margin: auto;
  justify-content: center;
  text-align: center;
  margin-left: 1rem;
  margin-right: 1rem;
  background-color: azure;
}

.rsvp-going {
  color: green;
  font-weight: bold;
  margin: 0.5rem;
}

.rsvp-not-going {
  color: red;
  font-weight: bold;
}

